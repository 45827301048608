import * as ActionType from "./sticker.type";

const initialState = {
  sticker: [],
  dialogOpen: false,
  dialogData: null,
};
export const StickerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_STICKER:
      return {
        ...state,
        sticker: action.payload,
      };
    case ActionType.OPEN_STICKER_DIALOG:
      return {
        ...state,
        dialogOpen: true,
        dialogData: action.payload || null,
      };
    case ActionType.CLOSE_STICKER_DIALOG:
      return {
        ...state,
        dialogOpen: false,
        dialogData: null,
      };

    case ActionType.CREATE_NEW_STICKER:
      let data = [...state.sticker];
      data.unshift(action.payload);
      return {
        ...state,
        sticker: data,
      };

    case ActionType.EDIT_STICKER:
      return {
        ...state,
        sticker: state.sticker.map((sticker) =>
          sticker?._id === action.payload.id ? action.payload.data : sticker
        ),
      };


    case ActionType.DELETE_STICKER:
      return {
        ...state,
        sticker: state.sticker.filter((data) => data._id !== action.payload),
      };
    default:
      return state;
  }
};
