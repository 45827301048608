export const GET_STICKER = "GET_STICKER";

export const CREATE_NEW_STICKER = "CREATE_NEW_STICKER";

export const EDIT_STICKER = "EDIT_STICKER";

export const DELETE_STICKER = "DELETE_STICKER";


export const OPEN_STICKER_DIALOG = "OPEN_STICKER_DIALOG";

export const CLOSE_STICKER_DIALOG = "CLOSE_STICKER_DIALOG";
